import React, { useContext, useEffect, useState } from "react";
import AuthorizeContext from "../../context/common/AuthorizeContext";
import OperationContext from "../../context/common/OperationContext";
import Navbar from "../common/Navbar";
import Sidebar from "../common/Sidebar";
import LoadingBar from 'react-top-loading-bar';
import InfiniteScroll from 'react-infinite-scroll-component';
import TriggerToastify from "../common/TriggerToastify";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { useNavigate } from 'react-router-dom';

const MyTeams = () => {

    const { authorizeUser } = useContext(AuthorizeContext);
    const { sidebarOpen } = useContext(OperationContext);
    const websiteName = process.env.REACT_APP_WEBSITE_NAME;
    const urlkey = process.env.REACT_APP_NODE_BASE_URL;
    const urlkey_team = process.env.REACT_APP_NODE_BASE_TEAM_URL;
    const [offsetListing, setOffsetListing] = useState(0);
    const [progressTopBar, setProgressTopBar] = useState(0);
    const [playersFound, setPlayersFound] = useState(0);
    const userToken = localStorage.getItem('userToken');
    const [searchPlayerVar, setSearchPlayerVar] = useState("");
    const [genderSelect, setGenderSelect] = useState("");
    const [ageRangeSelect, setAgeRangeSelect] = useState("");
    const [sportsSelectPlain, setSportsSelectPlain] = useState("");
    const [sportsSelect, setSportsSelect] = useState([]);
    var [teamstate, setteamstate] = useState([]);
    var [playerPlayingSports, setPlayerPlayingSports] = useState([{ label: "All Sports", value: "" }]);
    const animatedComponents = makeAnimated();
    const [filterSection, setFilterSection] = useState({ display: "none" });
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    //sidebar open close
    if (sidebarOpen === true) { var openSidebar = "toggled" } else { openSidebar = "" }

    //Tooltip
    tippy('.filterIcon', {
        content: "Filters",
        animation: 'fade',
    });

    //filter data
    const genderList = [{ label: "All Gender", value: "" }, { label: "Male", value: "m" }, { label: "Female", value: "f" }, { label: "Other", value: "o" }];
    const [genderDetails, setGenderDetails] = useState(genderList);

    const ageList = [{value:"", label:"All Age Groups"},{value:1, label:"8 to 12 years"},{value:2, label:"13 to 18 years"},{value:3, label:"19 to 45 years"},{value:4, label:"46 to 60 years"},{value:5, label:"61 and above"}];
    const [ageDetails, setAgeDetails] = useState(ageList);

    //listing Players With Filters
    const listingPlayer = { 'limit': 12, 'offset': offsetListing, 'search': searchPlayerVar, 'gender': genderSelect, 'age_range': ageRangeSelect, 'sports': sportsSelectPlain }
    
    useEffect(() => {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        authorizeUser();//Check user authorize
        teamsProfile(listingPlayer);//load players profile
        if (window.innerWidth > 720) {
            document.getElementById("filterIcon").click();
        }
        document.title = "Teams | " + websiteName;
    }, [websiteName])

    //tooltip
    setTimeout(function () {
        tippy('.addResultTeam', {
            content: "Remove team from my teams list.",
            animation: 'fade',
        });
    }, 3000);

    //fetch more profiles
    const fetchMoreData = () => {
        teamsProfile(listingPlayer);//load players profile
    }

    const teamsProfile = async (data) => {
        setProgressTopBar(30)
        var formBody = [];
        for (var property in data) {
            var encodedKeySignup = encodeURIComponent(property);
            var encodedValueSignup = encodeURIComponent(data[property]);
            formBody.push(encodedKeySignup + "=" + encodedValueSignup);
        }
        formBody = formBody.join("&");

        const urlkey = process.env.REACT_APP_NODE_BASE_URL;
        const homeURL = urlkey + 'teams/my_teams';
        const response = await fetch(homeURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + userToken
            },
            body: formBody
        });
        const json = await response.json();
        if (json !== "" && json !== undefined) {
            setProgressTopBar(100)
            if (json.status) {
                if (searchPlayerVar !== data.search) {
                    teamstate = [];
                    setSearchPlayerVar(data.search);
                    setOffsetListing(12);
                } else if (genderSelect !== data.gender) {
                    teamstate = [];
                    setGenderSelect(data.gender);
                    setOffsetListing(12);
                } else if (ageRangeSelect !== data.age_range) {
                    teamstate = [];
                    setAgeRangeSelect(data.age_range);
                    setOffsetListing(12);
                } else if (sportsSelectPlain !== data.sports) {
                    teamstate = [];
                    setSportsSelectPlain(data.sports);
                    setOffsetListing(12);
                } else {
                    setOffsetListing(offsetListing + 12);
                }

                //append players
                for (var inc = 0; inc < json.result.length; inc++) {
                    teamstate.push(json.result[inc])
                }
                setteamstate(teamstate);

                //total records
                setPlayersFound(json.total_records);
            } else if (json.status === false) {
                if (json.errors !== undefined && json.errors.length > 0) {
                    let errorAPiMessage = "";
                    for (let inc = 0; inc < json.errors.length; inc++) {
                        if (json.errors[inc].authorization !== "" && json.errors[inc].authorization !== undefined) {
                            errorAPiMessage = json.errors[inc].authorization;
                        }
                        if (json.errors[inc].limit !== "" && json.errors[inc].limit !== undefined) {
                            errorAPiMessage = json.errors[inc].limit;
                        }
                        if (json.errors[inc].offset !== "" && json.errors[inc].offset !== undefined) {
                            errorAPiMessage = json.errors[inc].offset;
                        }
                    }
                    TriggerToastify(errorAPiMessage, "error");
                } else {
                    TriggerToastify(json.message, "error");
                }
            }
        }
    }

    const searchbox = (event) => {
        var searchPlayerVar = event.target.value;
        searchPlayerVar = searchPlayerVar.trim();
        setOffsetListing(0);
        const listingPlayerSearch = { 'limit': 12, 'offset': 0, 'search': searchPlayerVar, 'gender': genderSelect, 'age_range': ageRangeSelect, 'sports': sportsSelectPlain }
        teamsProfile(listingPlayerSearch);//load players profile
    }

    //gender
    const genderChange = (genderSelectDetails) => {
        if (genderSelectDetails !== "" && genderSelectDetails.value !== undefined) {
            setGenderDetails(genderSelectDetails);
            setOffsetListing(0);
            const listingPlayerGender = { 'limit': 12, 'offset': 0, 'search': searchPlayerVar, 'gender': genderSelectDetails.value, 'age_range': ageRangeSelect, 'sports': sportsSelectPlain }
            teamsProfile(listingPlayerGender);//load players profile
        }
    }

    //age
    const ageChange = (ageSelectDetails) => {
        if (ageSelectDetails !== "" && ageSelectDetails.value !== undefined) {
            setAgeDetails(ageSelectDetails);
            setOffsetListing(0);
            const listingPlayerAge = { 'limit': 12, 'offset': 0, 'search': searchPlayerVar, 'gender': genderSelect, 'age_range': ageSelectDetails.value, 'sports': sportsSelectPlain }
            teamsProfile(listingPlayerAge);//load players profile
        }
    }

    //players Sports
    const playerPlayingSportsChange = (sportsSelectDetailsArray) => {
        if (sportsSelectDetailsArray !== "") {
            setSportsSelect(sportsSelectDetailsArray);
            var playSportsId = "";
            for (var sportsInc = 0; sportsInc < sportsSelectDetailsArray.length; sportsInc++) {
                playSportsId += sportsSelectDetailsArray[sportsInc].value + ",";
            }
            setOffsetListing(0);
            const listingPlayerSports = { 'limit': 12, 'offset': 0, 'search': searchPlayerVar, 'gender': genderSelect, 'age_range': ageRangeSelect, 'sports': playSportsId }
            teamsProfile(listingPlayerSports);//load players profile
        }
    }

    //hide show filter
    const showFilter = () => {
        if (filterSection.display === "none") {
            setFilterSection({ display: "block" });
        } else {
            setFilterSection({ display: "none" });
        }
    }

    //Submit challenge
    const handleSubmitChallenge = (team_player_id) => {//submit form with form data
        setProgressTopBar(30)

        const errorSubmit = [];

        if (team_player_id === "" || team_player_id === undefined) {
            setErrorMessage("Please enter team player id")
            errorSubmit.push(1)
        }

        //Submit form
        if (errorSubmit !== undefined && errorSubmit.length < 1) {

            var formData = {
                'team_player_id': team_player_id
            }
            removeTeams(formData);//update details by API
        } else {
            setProgressTopBar(100)
        }
    }

    //removeTeams
    const removeTeams = async (data) => {
        setProgressTopBar(30)
        var formBody = [];
        for (var property in data) {
            var encodedKeySignup = encodeURIComponent(property);
            var encodedValueSignup = encodeURIComponent(data[property]);
            formBody.push(encodedKeySignup + "=" + encodedValueSignup);
        }
        formBody = formBody.join("&");

        const urlkey = process.env.REACT_APP_NODE_BASE_URL;
        const my_teamsUrl = urlkey + 'teams/remove_teams';
        const response = await fetch(my_teamsUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + userToken
            },
            body: formBody
        });
        const json = await response.json();
        if (json !== "" && json !== undefined) {
            setProgressTopBar(100)
            if (json.status) {
                TriggerToastify(json.message, "success");
                //redirect to same page in 1 sec
                setTimeout(function() { navigate(0); }, 500);
            } else if (json.status === false) {
                if (json.errors !== undefined && json.errors.length > 0) {
                    let errorAPiMessage = "";
                    for (let inc = 0; inc < json.errors.length; inc++) {
                        if (json.errors[inc].team_player_id !== "" && json.errors[inc].team_player_id !== undefined) {
                            errorAPiMessage = json.errors[inc].team_player_id;
                        }
                    }
                    TriggerToastify(errorAPiMessage, "error");
                } else {
                    TriggerToastify(json.message, "error");
                }
            }
        }
    }

    return (
        <>
            <div className="container-fluid noMargin noPadding">
                <LoadingBar color='#f11946' height={2} shadow={true} progress={progressTopBar} onLoaderFinished={() => setProgressTopBar(0)} />
                <Navbar />
                <div id="wrapper" className={openSidebar}>
                    <Sidebar />
                    <div id="page-content-wrapper noMargin noPadding ">
                        <div className="container-fluid noMargin noPadding ">
                            <br /><br />
                            <div className="containDetails">

                                <div className="showPlayersHome">
                                    <div className="row noMargin">
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 noPadding fontStyle">
                                            <div style={filterSection}>
                                                <div className="row noMargin">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4"><Select options={genderList} defaultValue={!!genderDetails && genderDetails.value > 0 ? genderDetails : { label: "All Gender", value: "" }} onChange={genderChange} placeholder="Gender" /></div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4"><Select options={ageList} defaultValue={!!ageDetails && ageDetails.value > 0 ? ageDetails : { label: "All Age Groups", value: "" }} onChange={ageChange} placeholder="Age Range" /></div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4"><Select closeMenuOnSelect={false} components={animatedComponents} isMulti options={playerPlayingSports} defaultValue={!!sportsSelect && sportsSelect.length > 0 ? sportsSelect : ""} onChange={playerPlayingSportsChange} placeholder="All Sports" /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12 noPadding">
                                            <div className="iconStyle filterIcon" align="center" id="filterIcon" onClick={showFilter}>
                                                <span className="material-symbols-outlined filter">filter_alt</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 noPadding searchBox">
                                            <input type="search" name="search" className="form-control" defaultValue={searchPlayerVar} placeholder="Search Player Name" onChange={searchbox} />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 noPadding">
                                            <br />
                                            <h6 className="topHeadline">Total <b>{playersFound} teams</b> from your city</h6>
                                        </div>
                                    </div>
                                    <div className="row noMargin">
                                        <InfiniteScroll
                                            dataLength={teamstate.length}
                                            next={() => fetchMoreData()}
                                            hasMore={teamstate.length !== playersFound && playersFound !== undefined}
                                            loader={<h4>Loading...</h4>}
                                            scrollableTarget="scrollableDiv"
                                            className="row"
                                        >
                                            {
                                                teamstate.length > 0 ? teamstate.map((team, i) => {
                                                    var last_matches = team.last_matches;
                                                    if (last_matches !== null) {
                                                        var last_matches = last_matches.split(',');
                                                    }
                                                    return (<div className="col-sm-3 col-xs-3 col-md-3 col-lg-3" key={i}>
                                                        <div className="card-team">
                                                            <div className="pointerTeam">{team.all_sports}</div>
                                                            <div className="teamCard noBorder">
                                                                <div className="tshirtNumber tshirtNumberTooltip">{!!team.tshirt_number ? team.tshirt_number : ""}<span className="ot-border">{!!team.tshirt_number ? team.tshirt_number : ""}</span></div>
                                                                <div className="nameText">
                                                                    <div>{team.teamname}</div>
                                                                </div>
                                                            </div>
                                                            <div className="otherDetailsBlock">
                                                                <div className="otherDetailsTeam teamTooltipDetails">{team.type_name} ({team.gender == "m" ? "Male" : team.gender == "f" ? "Female" : team.gender == "b" ? "Both Male & Female" : team.gender == "o" ? "Other" : team.gender == "a" ? "All" : ""})</div>
                                                                <div className="otherDetailsTeam">Age Group: {team.age_range} </div>
                                                            </div>

                                                            <div className="teamCard teamCareer">
                                                                <div className="teamAlign"><img src={!!team.profile_img ? (urlkey_team + "images/" + team.profile_img) : "default_team.png"} className="img-responsive teamImgTeam" alt="team profile" /></div>
                                                            </div>
                                                            <div className="container">
                                                                <div align="center" className="pointerResult">Career</div>
                                                                <div className="teamCard teamCareer noBorder noPadding">
                                                                    <div className="columns"><span>{team.matches}</span> <br />Played</div>
                                                                    <div className="columns"><span>{team.won}</span> <br />Won</div>
                                                                    <div className="columns"><span>{team.draw}</span> <br />Draw</div>
                                                                </div>
                                                                <div className="teamRecent noBorder noPadding">
                                                                    {team.last_matches ? <div className="columns recentMatches"><b>Recent matches</b></div> : ""}
                                                                    <div className="winStats">
                                                                        {team.last_matches ? last_matches.map((matches, inc) => {
                                                                            if (matches != undefined && matches !== "" && matches !== null) {
                                                                                var colorMatch = "";
                                                                                if (matches === "L") {
                                                                                    var colorMatch = "recentPoints colorRed";
                                                                                } else if (matches === "D") {
                                                                                    var colorMatch = "recentPoints colorOrange";
                                                                                } else if (matches === "W") {
                                                                                    var colorMatch = "recentPoints colorGreen";
                                                                                }
                                                                                return (<div key={inc} className={colorMatch}>{matches}</div>)
                                                                            }
                                                                        }) : ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div align="center">
                                                                    {errorMessage ? '<div class="text text-danger">'+errorMessage+'</div>': ""}
                                                                    <button className="btn btn-primary addResultTeam" onClick={() => handleSubmitChallenge(team.team_player_id)}>Remove Team</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }) : ""
                                            }
                                        </InfiniteScroll>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyTeams;