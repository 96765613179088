import HeadTag from "./HeadTag";

const Header = () => {

    return (
        <>
        <HeadTag />
        </>
    );

}

export default Header;